import * as React from "react";
import PersonalLayout from "../../../../components/pages/personal/PersonalLayout";
import PersonalBody from "../../../../components/pages/personal/PersonalBody";
import {Button, Col, DatePicker, Form, Input, message, Row, TimePicker, Upload} from "antd";
import styled from "@emotion/styled";
import DarkButton from "../../../../components/buttons/darkButton";
import auth from "../../../../utils/auth";
import EducationUpload from "../../../../components/pages/personal/EducationUpload";
import Seo from "../../../../components/seo";
import {useState} from "react";
import jsonRequest from "../../../../utils/request/jsonRequest";
import {navigate} from "gatsby";

const UploadTitle = styled.div`
  line-height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,0.8);
  .titleDesc {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: normal;
  }
`

const UploadButton = styled.div`
  margin-top: 20px;
`

const UploadDesc = styled.div`
  margin-top: 8px;
  line-height: 20px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.4);
`

const Actions = styled.div`
  margin-top: 40px;
`

const PersonalEducationAdd = () => {
  auth.authorize()
  const [form] = Form.useForm()
  const [submitting, setSubmitting] = useState(false)
  const handleFinish = (values) => {
    if (submitting) {
      return;
    }
    const proveImages = values.proveImages?.filter(({status}) => status == 'done').map(({uid}) => uid) ?? []
    // if (proveImages.length == 0) {
    //   message.warn("请上传学历证明")
    //   return;
    // }
    setSubmitting(true);
    values.startTime.hour(0).minute(0).second(0)
    if (!!values.endTime) {
      values.endTime.hour(0).minute(0).second(0)
    }
    const formData = {
      ...values,
      startTime: values.startTime ? values.startTime.format("YYYY-MM-DDTHH:mm:ssZ") : "",
      endTime: values.endTime ? values.endTime.format("YYYY-MM-DDTHH:mm:ssZ") : "",
      proveImages: JSON.stringify(proveImages),
    }
    jsonRequest("/account/education", {
      method: 'POST',
      data: formData,
    }).then(rsp => {
      if (rsp.success) {
        message.success('保存成功')
        navigate("/personal/resume/education")
      } else {
        message.error(rsp.message)
      }
    }).catch(console.log).finally(() => {
      setSubmitting(false)
    })
  }
  return (<>
    <PersonalLayout selected='resume'>
      <PersonalBody
        title='编辑学习经历'
        backLink='/personal/resume/education'
      >
        <Form
          labelAlign='left'
          labelCol={{span: 6}}
          wrapperCol={{span: 18}}
          form={form}
          onFinish={handleFinish}
        >
          <Row gutter={40}>
            <Col span={12}>
              <Form.Item label='大学名称' name='school' required={false} rules={[{required: true, message: '请填写大学院校'}]}>
                <Input
                  placeholder='填写大学院校'
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/*<Form.Item label='在校时间' name='time' required={false} rules={[{required: true, message: '请选择在校时间'}]}>*/}
              <Form.Item label='在校时间' name='time' required={false}>
                <Row gutter={8}>
                  <Col span={12}><Form.Item noStyle name='startTime'><DatePicker/></Form.Item></Col>
                  <Col span={12}>
                    <Form.Item noStyle name='endTime'>
                      <DatePicker
                        placeholder='至今'
                        showToday={false}
                        renderExtraFooter={() => (
                          <Button type="link" onClick={() => {form.setFieldValue('endTime', null)}}>至今</Button>
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='所学专业' name='major' required={false} rules={[{required: true, message: '请填写学习专业'}]}>
                <Input
                  placeholder='填写学习专业'
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='最高学历' name='degree' required={false} rules={[{required: true, message: '请填写最高学历'}]}>
                <Input
                  placeholder='填写最高学历'
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name='content' noStyle>
                <Input.TextArea
                  placeholder='请添加文字描述'
                  rows={8}
                  maxLength={300}
                  showCount
                />
              </Form.Item>
            </Col>
          </Row>
          <UploadTitle>学历证明<span className="titleDesc">（选填，上传证明不对外展示，只用于平台审核）</span></UploadTitle>
          <UploadButton>
            <Form.Item name='proveImages' noStyle>
              <EducationUpload/>
            </Form.Item>
          </UploadButton>
          <UploadDesc>文件格式: JPG/PNG</UploadDesc>
          <Actions>
            <DarkButton onClick={form.submit} loading={submitting}>保存</DarkButton>
          </Actions>
        </Form>
      </PersonalBody>
    </PersonalLayout>
  </>)
}

export default PersonalEducationAdd;

export const Head = () => <Seo />
