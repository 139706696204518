import * as React from "react";
import {prefix} from "../../../utils/request/prefix";
import {UploadOutlined} from "@ant-design/icons";
import {Upload} from "antd";
import auth from "../../../utils/auth";
import {useSelector} from "react-redux";
import {useMemo} from "react";

export default (props: any) => {
  const {value = [], onChange = () => {}} = props
  auth.authorize()
  const {account} = useSelector((s: any) => s)
  const token = useMemo(auth.getToken, [account]);
  const handleChange = ({file, fileList}) => {
    if (file.status == 'done') {
      const path = file.response.data.path
      for (let i = fileList.length - 1; i >= 0; i--) {
        if (fileList[i].uid == file.uid) {
          fileList[i] = {
            uid: path,
            path,
            url: file.response.data.url,
            stages: 'done',
          }
        } else if (fileList[i].uid == path) {
          fileList.splice(i, 1)
        }
      }
    }
    onChange(fileList)
  }
  return (<>
    <Upload
      name='file'
      method='POST'
      action={`${prefix}/account/education/upload`}
      headers={{
        ...(!!token ? {
          "Authorization": token,
        } : {}) ,
      }}
      fileList={value}
      onChange={handleChange}
      listType='picture-card'
      accept='image/png,image/jpeg,image/jpg'
    >
      {/*<Button icon={<UploadOutlined/>}>上传文件</Button>*/}
      <UploadOutlined/> 上传
    </Upload>
  </>)
}
